// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDstZWmmeKAt8a-5iWXWs9quXfVuj2tve8",
  authDomain: "akhil-raj-66869.firebaseapp.com",
  projectId: "akhil-raj-66869",
  storageBucket: "akhil-raj-66869.appspot.com",
  messagingSenderId: "807973741004",
  appId: "1:807973741004:web:6b18cbde592006d56b3d69",
  measurementId: "G-GQGH9G6TR5",
  databaseURL:
    "https://akhil-raj-66869-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase
const auth = getAuth(app);
const db = getDatabase(app);
const analytics = getAnalytics(app);
export { auth, db };
export const COMPANY_NAME = "CA PRO PMS"; // "JVA PRIVATE LIMITED";
